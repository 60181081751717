<template>
    <div v-if="this.pageData">

        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="who we are">
        </HeaderIntroImage>

        <main>
            <section class="main-content">
                <div class="container">
                    <div class="box">
                        <p v-html="this.pageData.attributes.field_description_w_summary.value">
                        </p>
                        <div class="attachments-container">
                            <attachments v-if="pageData" :attachmentData="pageData"></attachments>
                        </div>
                    </div>
                </div>
            </section>

            <section v-if="this.teamsData && this.photosData">
                <div class="checkerboards">
                    <div v-for="(team, index) in this.teamsData" :key="'team'+index" class="row">

                        <!--<div class="text" v-if="index==0">
                            <div class="inner-box">
                                <h2>{{ team.attributes.title }}</h2>
                                <p v-html="team.attributes.field_description.value"></p>
                            </div>
                        </div> -->

                        <div class="text">
                            <div class="inner-box">
                                <h2> {{ team.attributes.title }} </h2>
                                <p v-html="team.attributes.field_description.value"></p>
                            </div>
                        </div>

                        <div class="people-card">
                            <div class="inner-box">
                                <div :key="'person'+ind" class="item"
                                    v-for="(person, ind) in team.field_ref_person">
                                    <div class="picture">
                                        <div>
                                            <div>
                                                <img :src="personphoto(person)" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h4 class="occhiello">CO-CHAIR</h4>
                                        <h2 class="name" :id="person.attributes.field_last_name">
                                            {{ person.attributes.field_first_name + ' ' + person.attributes.field_last_name }}
                                        </h2>
                                        <h3 class="role" v-if="person.attributes.field_person_position">
                                            {{ person.attributes.field_person_position }}
                                        </h3>
                                        <div class="box">
                                            <p ref="description" class="description"
                                                v-html="person.attributes.field_biography.value">
                                            </p>
                                            <a class="read-more accordion"
                                                @click="e => e.target.parentElement.classList.toggle('active')">
                                                Read more
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    </div>
</template>
<script>
import Attachments from '../components/Attachments.vue'

    import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
    import {
        fetchNodes,
        fetchSinglePage
    } from '../libs/drupalClient'
    export default {
        name: 'task-teams',
        components: {
            HeaderIntroImage,
            Attachments
        },
        data: () => {
            return {
                pageData: null,
                currentPageID: "3f5a848c-f8e3-4a82-87d2-76a314a41488",
                teamsData: null,
                photosData: null
            }
        },
        computed: {},
        methods: {
            logourl(index) {
                return process.env.VUE_APP_ENDPOINT + this.pageData.field_page_images[index].attributes.uri.url
            },
            personphoto(node) {
                var f = this.photosData.find(p => p.attributes.drupal_internal__nid == node.attributes
                    .drupal_internal__nid).field_photo.attributes.uri.url
                return process.env.VUE_APP_ENDPOINT + f
            }
        },
        async mounted() {
            await fetchSinglePage(this.currentPageID, {
                    include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text', 'field_attachments']
                })
                .then(res => {
                    this.pageData = res
                    //console.log('page data', res)
                })

            await fetchNodes('task_team', {
                    include: ['field_ref_person']
                })
                .then(res => {
                    this.teamsData = res
                    //console.log('task teams', this.teamsData)
                })

            await fetchNodes('person', {
                include: ['field_photo']
            }).then(res => {
                this.photosData = res
                //console.log('all persons', this.photosData)
            })
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/variables.scss";

    .occhiello {
        font-size: 14px;
        color: $orange;
        font-weight: 400;
    }


    div.item {
        margin-top: -3rem;
        margin-bottom: 3rem;
        position: relative;
        z-index: 3;
    }

    .box.active .description {
        display: block;
    }

    .header-intro {
        h3 {
            ::v-deep p {
                line-height: 1.5rem;
                font-weight: 400;
                margin-bottom: 0;
                margin-top: 0;
            }
        }
    }

    .checkerboards .row>div.people-card .inner-box {
        padding: 6rem 1rem 1rem;
    }

    .checkerboards {
        .inner-box {
            padding: 2rem 15px;

            .item {
                margin-bottom: 6rem;
            }

            .item:last-child {
                margin-bottom: 2rem;
            }
        }

        .text {
            position: relative;

            &:after {
                content: "";
                width: 0px;
                height: 0px;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-top: 15px solid #fff;
                bottom: -15px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            h2 {
                margin-bottom: 0;
                color: $darkblue;
                font-size: 1.5rem;
            }

            h3 {
                margin-top: 0;
                line-height: 1.5rem;
                font-size: 1.25rem;
                color: $green;
                font-weight: 300;
            }
        }


    }

    @media (min-width:$container-breakpoints-sm) {
        .checkerboards {
            .row {

                .item,
                .text * {
                    max-width: 540px;
                    margin: 0px auto;
                }

                .text h2 {
                    font-size: 1.875rem;
                    margin-bottom: 0.3125rem;
                }

                .text h3 {
                    margin-bottom: 2rem;
                }

                .item {
                    margin: 2rem auto 4rem auto;
                }
            }
        }
    }

    @media (min-width:768px) {
        .checkerboards .row>div.people-card .inner-box {
            padding: 1rem 1rem 1rem;
        }
    }

    @media (min-width:$container-breakpoints-lg) {



        .checkerboards .row>div.people-card .inner-box {
            padding: 1rem 1rem 1rem;
        }

        .checkerboards {
            .row:nth-child(even) {
                flex-direction: row-reverse;

                .text:after {
                    transform: rotate(90deg) translateX(0) translateY(-50%);
                    right: -30px;
                    right: auto;
                    left: -30px;
                }
            }

            .row {
                display: flex;

                >div {
                    width: 50%;
                }

                >div.people-card .inner-box {
                    padding: 3rem 2rem;
                    width: calc(100% - 4rem);
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                    &:after {
                        content: "";
                        width: 0px;
                        height: 0px;
                        border-left: 15px solid transparent;
                        border-right: 15px solid transparent;
                        border-top: 15px solid #fff;
                        bottom: 50%;
                        position: absolute;
                        left: auto;
                        transform: rotate(270deg) translateX(0) translateY(-50%);
                        right: -30px;
                    }

                    h2 {
                        text-align: left;
                    }
                }
            }
        }
    }

    @media (min-width:$container-breakpoints-xxl) {
        .checkerboards .row {

            &:nth-child(even) {

                .people .item {
                    margin: 1rem 5rem 4rem auto;
                }

                .text {
                    align-items: flex-start;

                    h2,
                    h3,
                    >p {
                        width: 540px;
                        margin-right: auto;
                    }
                }
            }

            .text {
                h2,
                h3,
                p {
                    width: 540px;
                    margin-right: 5rem;
                }

                h2 {
                    font-size: 2.5rem;
                    line-height: 2.5rem;
                }

                h3 {
                    font-size: 1.75rem;
                    line-height: 2rem;
                }
            }
        }
    }
</style>